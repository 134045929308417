// @flow
import environment from "./endpoints";

const PROD_API_URL = "https://admin-dmp.insigit.com";

export default {
  ...environment(PROD_API_URL),
  appLinks: {
    dmp: "https://admin-dmp.insigit.com/",
    jus: "https://jus.insigit.com/",
    cpa: "https://cpa-admin.insigit.com/",
    webPush: "https://webpush.noclef.com/",
    adsBox: "https://adsbox.insigit.com/",
    reports: "https://reports.insigit.com/managersReport",
    crm: "https://crm.insigit.com/",
  },
};
